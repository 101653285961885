import Vue from "vue";
import Router from "vue-router";
import store from "@/store/index";
import permissions_utils from "@/utils/permissions_utils";

//MARK: - Authentication

import Login from "@/views/authentication/Login";
import SignUp from "@/views/authentication/SignUp";
import VerificationPhone from "@/views/authentication/VerificationPhone";
import TwoFactor from "@/views/authentication/TwoFactor";
import ForgotPassword from "@/views/authentication/ForgotPassword";

//MARK: - Dashboard

import Overview from "@/views/dashboard/Overview";
import Reports from "@/views/dashboard/Reports";
import Reporters from "@/views/dashboard/Reporters";
import Accused from "@/views/dashboard/Accused";
import Locations from "@/views/dashboard/Locations";
import Behaviors from "@/views/dashboard/Behaviors";
import Places from "@/views/dashboard/Places";
import Analytics from "@/views/dashboard/Analytics";

import Report from "@/views/dashboard/Report";
// import ReportStep from "@/views/dashboard/ReportStep";
// import ReportCreation from "@/views/dashboard/ReportCreation";

//MARK: - Misc

import Dashboard from "@/layouts/DashboardLayout";
import NotFound from "@/components/NotFound";
import url_utils from "../utils/url_utils";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "*",
      redirect: "/index"
    },
    {
      name: "dashboard",
      path: "/",
      component: Dashboard,
      meta: {
        requiresLogin: true,
        requiresRedirect: true,
        permissions: ["view dashboard", "view dashboard overview"]
      },
      children: [
        {
          name: "overview",
          path: "index",
          component: Overview,
          meta: {
            requiresLogin: true,
            permissions: ["view dashboard", "view dashboard overview", "generate company analytics"],
            sidebar: {
              title: "pages.dashboard.overview",
              color: "#441313",
              icon: "fa-home",
              addSeparator: true
            }
          },
          default: true
        },
        {
          name: "reports",
          path: "reports",
          meta: {
            requiresLogin: true,
            permissions: ["view dashboard", "view dashboard overview"],
            sidebar: {
              title: "pages.dashboard.reports",
              color: "#c1473a",
              icon: "fa-clipboard"
            }
          },
          component: Reports
        },
        {
          name: "report",
          path: "reports/:id",
          meta: {
            requiresLogin: true,
            permissions: ["view dashboard", "view dashboard overview"]
          },
          component: Report
        },
        // {
        //   name: "ReportCreation",
        //   path: "reports/create",
        //   meta: {
        //     requiresLogin: true,
        //     permissions: ["view dashboard"]
        //   },
        //   component: ReportCreation
        // },
        // {
        //   name: "step",
        //   path: "reports/:id/step",
        //   meta: {
        //     requiresLogin: true,
        //     permissions: ["view dashboard"]
        //   },
        //   component: ReportStep
        // },
        {
          name: "reporters",
          path: "reporters",
          meta: {
            requiresLogin: true,
            permissions: ["view dashboard", "view dashboard overview"],
            sidebar: {
              title: "pages.dashboard.reporters",
              color: "#001B39",
              icon: "fa-gavel"
            }
          },
          component: Reporters
        },
        {
          name: "accused",
          path: "accused",
          meta: {
            requiresLogin: true,
            permissions: ["view dashboard", "view dashboard overview"],
            sidebar: {
              title: "pages.dashboard.accused",
              color: "#1c78a5",
              icon: "fa-users"
            }
          },
          component: Accused
        },
        {
          name: "locations",
          path: "locations",
          meta: {
            requiresLogin: true,
            permissions: ["view dashboard", "view dashboard overview"],
            sidebar: {
              title: "pages.dashboard.locations",
              color: "#5f9e83",
              icon: "fa-map"
            }
          },
          component: Locations
        },
        {
          name: "places",
          path: "places",
          meta: {
            requiresLogin: true,
            permissions: ["view dashboard", "view dashboard overview"],
            sidebar: {
              title: "pages.dashboard.places",
              color: "#3A6E58",
              icon: "fa-map-pin"
            }
          },
          component: Places
        },
        {
          name: "behaviors",
          path: "behaviors",
          meta: {
            requiresLogin: true,
            permissions: ["view dashboard", "view dashboard overview"],
            sidebar: {
              title: "pages.dashboard.behaviors",
              color: "#1f5688",
              icon: "fa-comments"
            }
          },
          component: Behaviors
        },
        {
          name: "analytics",
          path: "analytics",
          meta: {
            requiresLogin: true,
            permissions: ["view dashboard", "generate company analytics"],
            sidebar: {
              title: "pages.dashboard.analytics",
              color: "#C69C85",
              icon: "fa-chart-line",
              addSeparator: true
            }
          },
          component: Analytics
        },
        {
          name: "user-reports",
          path: "user-reports",
          meta: {
            requiresLogin: true,
            permissions: ["read own report"],
            sidebar: {
              title: "pages.dashboard.walk_in_reports",
              color: "#1F5688",
              icon: "fa-handshake"
            }
          },
          beforeEnter() {
            window.open(`https://${url_utils.getAppUrl()}/index`, '_self')
          }
        },
        {
          name: "feed",
          path: "feed",
          meta: {
            requiresLogin: true,
            permissions: ["view dashboard", "read feed"],
            sidebar: {
              title: "pages.settings.feed",
              color: "#D8AD00",
              icon: "fa-newspaper"
            }
          },
          beforeEnter() {
            window.open(`https://${url_utils.getAppUrl()}/feed`, '_self')
          }
        },
        {
          name: "just-sayin",
          path: "just-sayin",
          meta: {
            requiresLogin: true,
            permissions: ["view dashboard", "view company voices"],
            sidebar: {
              title: "pages.dashboard.just_sayin",
              color: "#C47300",
              icon: "fa-bullhorn",
            }
          },
          beforeEnter() {
            window.open(`https://${url_utils.getAppUrl()}/just-sayin`, '_self')
          }
        }
      ]
    },
    {
      name: "login",
      path: "/login",
      component: Login,
      meta: { requiresLogin: false }
    },
    {
      name: "signup",
      path: "/signup",
      component: SignUp,
      meta: { requiresLogin: false }
    },
    {
      name: "VerificationPhone",
      path: "/verification",
      component: VerificationPhone,
      meta: { requiresLogin: true, requiresPhoneNumber: true }
    },
    {
      name: "TwoFactor",
      path: "/two-factor",
      component: TwoFactor,
      props: true,
      meta: { requiresLogin: false }
    },
    {
      name: "ForgotPassword",
      path: "/forgot-password",
      component: ForgotPassword,
      meta: { requiresLogin: false }
    },
    {
      name: "404",
      path: "/404",
      component: NotFound,
      meta: { requiresLogin: false }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(record => record.meta.requiresLogin) &&
    !store.state.auth.token
  ) {
    next("/login");
  } else {
    if (
      !to.matched.some(record => record.meta.requiresPhoneNumber) &&
      store.state.auth.user &&
      !JSON.parse(store.state.auth.user).phone_verified
    ) {
      if (from.path === "/verification") {
        next()
      }
      else {
        next("/verification");
      }
    } else if (to.name.startsWith('settings') && store.state.auth.role.title === 'viewer') {
      next("/index");
    } else if (store.state.auth.role && store.state.auth.role.permissions) {
      if (to.matched.some(record => record.meta.permissions)) {
        to.meta.permissions.forEach(element => {
          if (Array.isArray(element)) {
            let canGoNext = false;
            element.forEach(permission => {
              if (permissions_utils.can(permission)) {
                canGoNext = true;
              }
            });
            if (!canGoNext) {
              next("/404");
            }
          } else {
            if (!permissions_utils.can(element)) {
              if (element === "read company profile") {
                next("/settings/members");
              } else {
                next(permissions_utils.getDefaultRoute());
              }
            }
          }
        });
      } else if (
        to.matched.some(record => !record.meta.requiresLogin) &&
        store.state.auth.token
      ) {
        next("/index");
      }
    }
    next();
  }
});

export default router;
